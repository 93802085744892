<template>
  <div id="AccountData">
    <van-nav-bar
      :fixed="true"
      z-index="10"
      placeholder
      :title="$t('My.PersonalInformation.AccountData.title')"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-tabs v-model="active" @click="handleTabs">
      <van-tab
        v-for="(item, index) in tabList"
        :key="index"
        :title="item.title"
      >
        <div class="tabs-item">
          <div
            class="item-content"
            v-for="(item, index) in newList"
            :key="index"
          >
            <div>
              {{ $t('My.PersonalInformation.RechargeRecord.Amount')
              }}{{ item.price }}
            </div>
            <div>{{ $t('Hint.commissionMoney') }}{{ item.reward }}</div>
            <div>
              {{ $t('My.PersonalInformation.RechargeRecord.Order')
              }}{{ item.order_id }}
            </div>
            <!-- 充值状态暂时隐藏 -->
            <!-- <div>
              {{ $t('My.PersonalInformation.RechargeRecord.Status')
              }}<span>{{ item.status === 1 ? 'have' : 'not have' }}</span>
            </div> -->
            <div>
              <p>{{ item.expireTimeShow }}</p>
            </div>
            <!-- <button
              class="withBtn"
              @click="handleWithdrawal(item)"
              v-if="item.isShowBtn"
            >
              {{ tabList[1].title }}
            </button> -->
            <button
              class="withBtn"
              :class="
                !item.receiveStatus
                  ? 'withBtn3'
                  : item.receiveStatus === 1
                  ? 'withBtn2'
                  : 'withBtn1'
              "
              @click="handleWithdrawal(item)"
            >
              {{
                !item.receiveStatus
                  ? btnObj.depositBtnText3
                  : item.receiveStatus === 1
                  ? btnObj.depositBtnText2
                  : btnObj.depositBtnText1
              }}
            </button>
          </div>
        </div>
      </van-tab>
      <!-- 底部分页的东西 -->
      <div class="bottom-content">
        <div class="Text">
          Total {{ Paging.Total }} records, each page displays
          {{ Paging.PageSize }}, total {{ Paging.Num }} pages.
        </div>
        <van-pagination
          prev-text="<"
          next-text=">"
          v-model="Paging.CurrentPage"
          mode="simple"
          :total-items="Paging.Total"
          :items-per-page="Paging.PageSize"
          @change="PageEvent"
        />
      </div>
    </van-tabs>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Tab,
  Tabs,
  Image,
  NoticeBar,
  Field,
  DropdownMenu,
  DropdownItem,
  Popup,
  DatetimePicker,
  Pagination,
  Toast,
} from 'vant'
import { REQUEST_API } from '@/http/api'
import { getTimeStamp, getMyTime2 } from '@/utils/tools'
import { countTime, getMyTime, deciTurnMoney } from '@/utils/tools'
export default {
  name: 'AccountData',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      active: 0, // 标签选中的值
      tabList: [
        {
          title: 'deposit',
          key: 1,
        },
        {
          title: 'withdrawal',
          key: 2,
        },
      ],
      TabelData: [
        {
          money: '100.000',
          Num: 'SY201105021116039',
          Status: 'Pending review',
        },
      ], // 列表渲染的数据
      // 分页的数据
      Paging: {
        PageSize: 10, // 当前一页多少条
        CurrentPage: 1, // 当前页
        Num: '', // 分页的数量
        Total: 0, // 总条数
      },
      list: [],
      userInfo: [],
      pageNo: 1,
      tabStatus: 1,
      btnObj: {
        depositBtnText1: this.$t('Hint.depositBtnText1'), // 已领取
        depositBtnText2: this.$t('Hint.depositBtnText2'), // 可领取
        depositBtnText3: this.$t('Hint.depositBtnText3'), // 领取
      },
    }
  },
  computed: {
    newList() {
      let time = new Date().valueOf()
      let list = this.list.map((_) => ({
        ..._,
        isShowBtn:
          _.status === 2
            ? false
            : _.status === 1 && time >= _.end_time
            ? true
            : false,
        /**
         * TO DO
         * receiveStatus 理财订单的领取状态（说明：0是不可领取 1 是可领取 但是没有领取 2 是已领取
         */
        expireTimeShow: getMyTime2(_.create_time),
        price: deciTurnMoney(_.price),
        reward: deciTurnMoney(_.reward),
      }))
      return list
    },
  },
  created() {
    this.init()
    this.depositList()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    }, // 返回上一个路由
    // 分页的点击事件
    PageEvent() {
      let data = {
        cmd: '',
        status: null,
      }
      this.list = []
      if (!this.active) {
        data.cmd = '202442'
        data.status = 1
      } else {
        data.cmd = '202442'
        data.status = 2
      }
      this.depositList(data)
    },
    handleTabs(name, title) {
      let data = {
        cmd: '',
        status: null,
      }
      if (!name) {
        data.cmd = '202442'
        data.status = 1
      } else {
        data.cmd = '202442'
        data.status = 2
      }
      this.tabStatus = data.status
      this.Paging.CurrentPage = 1
      this.list = []
      this.depositList(data)
    },
    handleWithdrawal(item) {
      if (item.receiveStatus === 1) {
        this.withdrawal(item)
      }
      /**
       * TO DO
       * a标签跳转其他页面
       */
      // let a = document.createElement('a')
      // a.href = '//baidu.com'
      // a.click()
    },
    /** API **/
    depositList(data = { cmd: '202442', status: 1 }) {
      let form = {
        page: this.Paging.CurrentPage,
        pageNum: 10,
        uid: this.userInfo[1].data.player.uid,
        cmd: data.cmd,
        status: data.status,
      }
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            if (
              r.data.notifyList[0] !== undefined &&
              r.data.notifyList[0].data.count
            ) {
              console.log('存取款记录', r.data.notifyList[0].data.data)
              this.list.push(...r.data.notifyList[0].data.data)
              this.finished =
                r.data.notifyList[0].data.page ===
                r.data.notifyList[0].data.pageSize
              this.pageNo++
              if (this.finished) {
                this.loading = false
              }
              this.Paging.Num = Math.ceil(
                r.data.notifyList[0].data.count /
                  r.data.notifyList[0].data.pageSize
              )
              this.Paging.Total = r.data.notifyList[0].data.count
            } else {
              this.Paging.Num = 0
              this.Paging.Total = 0
              this.list = []
              this.loading = false
            }
          } else {
            this.Paging.Num = 0
            this.Paging.Total = 0
            this.list = []
            this.loading = false
            Toast(this.$t('Hint.failed'))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }, // 存取款list
    withdrawal(item) {
      let form = {
        uid: this.userInfo[1].data.player.uid,
        cmd: '202443',
        id: item.id,
      }
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            Toast(this.$t('Hint.success'))
            this.list = []
            this.depositList({ cmd: '202442', status: this.tabStatus })
          } else {
            Toast(this.$t('Hint.failed'))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    /** 其他 **/
    init() {
      this.userInfo = JSON.parse(localStorage.getItem('user-info'))
    },
  },
}
</script>

<style lang="less" scoped>
#AccountData {
  width: 100%;
  height: 100%;
  .tabs-item {
    width: 100%;
    > .item-content {
      width: calc(100% - 1.25rem);
      border-bottom: 1px solid #ccc;
      padding: 0.625rem;
      position: relative;
      > div {
        font-size: 0.75rem;
        line-height: 1.25rem;
        font-weight: 400;
        > span {
          color: red;
        }
      }
      > div:nth-child(1) {
        font-size: 0.875rem;
      }
      > .withBtn {
        border: 0;
        font-size: calc(15rem / 16);
        padding: calc(3rem / 16) calc(8rem / 16);
        position: absolute;
        bottom: calc(15rem / 16);
        right: calc(15rem / 16);
        color: var(--textColor);
        // background: #f33f3f;
        border-radius: 2px;
      }
      > .withBtn1 {
        background: #918989; // 已领取
      }
      > .withBtn2 {
        background: #f33f3f; // 可领取
      }
      > .withBtn3 {
        background: #918989; // 不可领取
      }
    }
  }
  .van-tabs {
    .van-tabs__wrap {
      border-bottom: 1px solid #eee;
    }
  }
  .bottom-content {
    width: 100%;
    > .Text {
      width: 100%;
      text-align: center;
      line-height: 1.25rem;
      color: #666;
      font-size: 0.75rem;
    }
  }
}
</style>